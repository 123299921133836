'use strict'
Object.defineProperty(exports, '__esModule', { value: true })
exports.ProviderMenuWelcome = void 0
const jsx_runtime_1 = require('react/jsx-runtime')
const icons_1 = require('../icons')
const ProviderMenuWelcome = () => {
  return (0, jsx_runtime_1.jsx)(
    'div',
    Object.assign(
      { className: 'provider-menu__welcome' },
      {
        children: (0, jsx_runtime_1.jsxs)(
          'div',
          Object.assign(
            { className: 'provider-menu-welcome' },
            {
              children: [
                (0, jsx_runtime_1.jsx)(
                  'div',
                  Object.assign(
                    { className: 'provider-menu-welcome__image' },
                    { children: (0, jsx_runtime_1.jsx)(icons_1.GuruImage, {}, void 0) }
                  ),
                  void 0
                ),
                (0, jsx_runtime_1.jsx)(
                  'h2',
                  Object.assign(
                    { className: 'provider-menu-welcome__title' },
                    { children: 'This will allow DexGuru to:' }
                  ),
                  void 0
                ),
                (0, jsx_runtime_1.jsx)(
                  'div',
                  Object.assign(
                    { className: 'provider-menu-welcome__body' },
                    {
                      children: (0, jsx_runtime_1.jsxs)(
                        'ul',
                        Object.assign(
                          { className: 'provider-menu__features' },
                          {
                            children: [
                              (0, jsx_runtime_1.jsx)(
                                'li',
                                Object.assign(
                                  { className: 'provider-menu__feature pro' },
                                  { children: 'See your wallet address and tokens balances' }
                                ),
                                void 0
                              ),
                              (0, jsx_runtime_1.jsx)(
                                'li',
                                Object.assign(
                                  { className: 'provider-menu__feature pro' },
                                  {
                                    children:
                                      'Sync app settings and your favorites list across devices',
                                  }
                                ),
                                void 0
                              ),
                              (0, jsx_runtime_1.jsx)(
                                'li',
                                Object.assign(
                                  { className: 'provider-menu__feature con' },
                                  { children: 'Buy you a nice seafood dinner' }
                                ),
                                void 0
                              ),
                            ],
                          }
                        ),
                        void 0
                      ),
                    }
                  ),
                  void 0
                ),
                (0, jsx_runtime_1.jsx)(
                  'div',
                  Object.assign(
                    { className: 'provider-menu-welcome__footer' },
                    {
                      children:
                        'DexGuru is a fully non-custodial platform, so the assets in your wallet are always under your control.',
                    }
                  ),
                  void 0
                ),
              ],
            }
          ),
          void 0
        ),
      }
    ),
    void 0
  )
}
exports.ProviderMenuWelcome = ProviderMenuWelcome
exports.default = exports.ProviderMenuWelcome
