'use strict'
Object.defineProperty(exports, '__esModule', { value: true })
exports.ProviderMenuList = void 0
const jsx_runtime_1 = require('react/jsx-runtime')
const types_1 = require('../types')
const ProviderMenuEntryContents = ({ caption, icon }) => {
  const Icon = icon
  return (0, jsx_runtime_1.jsxs)(
    jsx_runtime_1.Fragment,
    {
      children: [
        (0, jsx_runtime_1.jsx)(
          'span',
          Object.assign(
            { className: 'icon' },
            { children: (0, jsx_runtime_1.jsx)(Icon, {}, void 0) }
          ),
          void 0
        ),
        (0, jsx_runtime_1.jsx)(
          'span',
          Object.assign({ className: 'name' }, { children: caption }),
          void 0
        ),
      ],
    },
    void 0
  )
}
const ProviderMenuList = ({ isMobile, connectors, providerLogos, onConnect }) => {
  const agent = window.ethereum
  const agentIsMetaMask = !!agent && !!agent.isMetaMask
  const agentIsTrustWallet = !!agent && !!agent.isTrust
  const agentIsWalletConnect = !agentIsMetaMask && !agentIsTrustWallet
  const allConnectors =
    connectors === null || connectors === void 0 ? void 0 : connectors.getConnectors()
  return (0, jsx_runtime_1.jsx)(
    'div',
    Object.assign(
      { className: 'provider-menu-providers' },
      {
        children:
          allConnectors &&
          Object.keys(allConnectors).map((providerName) => {
            if (!providerName || providerName === types_1.ProviderName.Unknown) {
              return null
            }
            if (isMobile) {
              if (agentIsWalletConnect && providerName !== types_1.ProviderName.WalletConnect) {
                return null
              }
              if (agentIsTrustWallet && providerName !== types_1.ProviderName.TrustWallet) {
                return null
              }
              if (agentIsMetaMask && providerName !== types_1.ProviderName.MetaMask) {
                return null
              }
            } else {
              if (providerName === types_1.ProviderName.TrustWallet) {
                return null
              }
            }
            const providerLogo = providerLogos[providerName]
            if (!providerLogo) {
              return null
            }
            const subtitle = agent
              ? 'Use this 👆 button if you are using dApp browser'
              : 'Use this 👆 button for mobile wallets: MetaMask Mobile, Trust  Wallet,  Rainbow, Argent, etc'
            return (0, jsx_runtime_1.jsx)(
              'div',
              Object.assign(
                { className: 'provider-menu-providers__entry' },
                {
                  children:
                    !isMobile && !agent && providerName === types_1.ProviderName.MetaMask
                      ? (0, jsx_runtime_1.jsx)(
                          'a',
                          Object.assign(
                            {
                              className:
                                'provider-menu-providers__button provider-menu-providers__button--install',
                              href: 'https://metamask.io/',
                              target: '_blank',
                              rel: 'noreferrer',
                            },
                            {
                              children: (0, jsx_runtime_1.jsx)(
                                ProviderMenuEntryContents,
                                { caption: 'Install MetaMask', icon: providerLogo },
                                void 0
                              ),
                            }
                          ),
                          void 0
                        )
                      : (0, jsx_runtime_1.jsxs)(
                          jsx_runtime_1.Fragment,
                          {
                            children: [
                              (0, jsx_runtime_1.jsx)(
                                'button',
                                Object.assign(
                                  {
                                    className: 'provider-menu-providers__button',
                                    onClick: () => {
                                      onConnect(providerName)
                                    },
                                  },
                                  {
                                    children: (0, jsx_runtime_1.jsx)(
                                      ProviderMenuEntryContents,
                                      { caption: providerName.toString(), icon: providerLogo },
                                      void 0
                                    ),
                                  }
                                ),
                                void 0
                              ),
                              (0, jsx_runtime_1.jsx)(
                                'div',
                                Object.assign(
                                  { className: 'provider-menu-providers__subtitle' },
                                  { children: subtitle }
                                ),
                                void 0
                              ),
                            ],
                          },
                          void 0
                        ),
                }
              ),
              providerName
            )
          }),
      }
    ),
    void 0
  )
}
exports.ProviderMenuList = ProviderMenuList
exports.default = exports.ProviderMenuList
